import "../../index.css";
import Header from "./Header";
import Footer from "./Footer";
import Hero from "./Hero";
import AppHighlights from "./AppHighlights";
import AppFeatures from "./AppFeatures";
import Testimonials from "./Testimonials";

const HomePage = () => {
    return (
        <div className="w-screen flex flex-col justify-center items-center bg-[#0E0F0C] overflow-x-clip">
            {/* Header */}
            <Header />

            {/* Quiz and side carousels */}
            <Hero />

            {/* App Highlights */}
            <AppHighlights />

            {/* App features */}
            <AppFeatures />

            {/* Testimonials */}
            <Testimonials />

            {/* Footer */}
            <Footer />

            <div className="w-screen h-[50px]"/>
        </div>
    )
}

export default HomePage;
