import useGA from "../../hooks/useGA";
import { FaApple } from "react-icons/fa";
import { GA_EVENTS } from "../../constants/dataConstant";

const CTAButton = () => {

    const { recordGAEvent } = useGA();

    const handleCTAClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        recordGAEvent(GA_EVENTS.STICKY_CTA_CLICK);
        window.open("https://apps.apple.com/in/app/muzify-inc/id6449702693", "_blank");
    }

    return (
        <button onClick={(e) => handleCTAClick(e)}>
            <div className="w-[120px] md:w-[150px] h-[40px] md:h-[52px] rounded-full flex justify-center items-center shadow-[0px_0px_4px_4px_rgba(254,195,48,0.3)] bg-gradient-to-br from-[#FFEBBA] to-[#98751D]">
                <div className="w-[116px] md:w-[146px] h-[36px] md:h-[48px] rounded-full bg-[#FEC330] flex flex-row justify-center items-center font-inter font-semibold text-[12px] md:text-[16px] leading-[120%] text-[#0E0F0C]">
                    <div>Download</div>
                    <div className="flex flex-col justify-center items-center">
                        <FaApple size={"1.5em"} />
                        <div className="h-[3px]"/>
                    </div>
                </div>
            </div>
        </button>
    )
}

export default CTAButton;
