import Quiz from "./Quiz";
import { HOMEPAGE_ARTISTS } from "../../constants/dataConstant";

let usedIndices = new Set();

const pickRandomArtworkFromHomepageArtists = () => {
    let randomIndex;
    do {
      randomIndex = Math.floor(Math.random() * HOMEPAGE_ARTISTS.length);
    } while (usedIndices.has(randomIndex));
  
    usedIndices.add(randomIndex);
    return HOMEPAGE_ARTISTS[randomIndex].artwork;
};

const images1 = Array.from({ length: 6 }, pickRandomArtworkFromHomepageArtists);
const images2 = Array.from({ length: 6 }, pickRandomArtworkFromHomepageArtists);
const images3 = Array.from({ length: 6 }, pickRandomArtworkFromHomepageArtists);
const images4 = Array.from({ length: 6 }, pickRandomArtworkFromHomepageArtists);
const images5 = Array.from({ length: 6 }, pickRandomArtworkFromHomepageArtists);

const Hero = () => {
    return (
        <div className="w-screen h-[650px] md:h-[740px] flex flex-col md:flex-row justify-between items-center overflow-hidden bg-[#0E0F0C]">
            <div className="hidden md:flex md:flex-row items-center justify-center grow-0 gap-5 pl-10 relative">
                <div className="flex flex-col gap-8">
                    {
                        images1.map((image, index) => (
                            <img
                                key={`image-${index}`}
                                src={image}
                                alt={`image-${index}`}
                                style={{ opacity: "0.6" }}
                                className="w-40 h-52 animate-image-container-right rounded-3xl object-cover"
                            />
                    ))}
                </div>

                <div className="flex flex-col gap-8">
                    {
                        images2.map((image, index) => (
                            <img
                                key={`image-${index}`}
                                src={image}
                                alt={`image-${index}`}
                                style={{ opacity: "0.6" }}
                                className="w-40 h-52 rounded-3xl animate-image-container-left object-cover"
                            />
                    ))}
                </div>
                <div className="absolute left-0 top-0 w-[100%] h-[50%] bg-gradient-to-b from-[#0E0F0C] via-[#0E0F0C] to-transparent z-2"/>
                <div className="absolute left-0 bottom-0 w-[100%] h-[50%] bg-gradient-to-t from-[#0E0F0C] via-[#0E0F0C] to-transparent z-2"/>
            </div>

            <div className="block h-[40px] md:hidden"/>

            <div className="block h-[50px] md:hidden"/>
            <div className="flex justify-center items-center">
                <Quiz />
            </div>

            <div className="flex flex-row gap-3 mt-[10px] md:hidden relative">
                {
                    images5.map((image, index) => (
                        <img
                            key={`image-${index}`}
                            src={image}
                            alt={`image-${index}`}
                            style={{ opacity: "0.6" }}
                            className="w-28 h-36 rounded-3xl animate-slide-left object-cover"
                        />
                    ))
                }
                <div className="absolute left-0 w-[40%] h-[100%] bg-gradient-to-r from-[#0E0F0C] to-transparent z-2"/>
                <div className="absolute right-0 w-[40%] h-[100%] bg-gradient-to-l from-[#0E0F0C] to-transparent z-2"/>
            </div>

            <div className="hidden md:flex md:flex-row items-center justify-center grow-0 gap-5 pr-10 relative">
                <div className="flex flex-col gap-8">
                    {
                        images3.map((image, index) => (
                            <img
                                key={`image-${index}`}
                                src={image}
                                alt={`image-${index}`}
                                style={{ opacity: "0.6" }}
                                className="w-40 h-52 animate-image-container-right rounded-3xl object-cover"
                            />
                    ))}
                </div>

                <div className="flex flex-col gap-8">
                    {
                        images4.map((image, index) => (
                            <img
                                key={`image-${index}`}
                                src={image}
                                alt={`image-${index}`}
                                style={{ opacity: "0.6" }}
                                className="w-40 h-52 rounded-3xl animate-image-container-left object-cover"
                            />
                    ))}
                </div>
                <div className="absolute left-0 top-0 w-[100%] h-[50%] bg-gradient-to-b from-[#0E0F0C] via-[#0E0F0C] to-transparent z-2"/>
                <div className="absolute left-0 bottom-0 w-[100%] h-[50%] bg-gradient-to-t from-[#0E0F0C] via-[#0E0F0C] to-transparent z-2"/>
            </div>

        </div>
    )
}

export default Hero;
