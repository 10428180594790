import CTAButton from "./CTAButton";

const Header = () => {
    return (
        <div className="flex justify-center items-center fixed top-[10px] md:top-[40px] right-[10px] md:right-[50px] z-20">
            <CTAButton />
        </div>
    )
}

export default Header;
