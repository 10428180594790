import ReactGA from "react-ga4";

export default function useGA() {

    function recordGAEvent(eventName: string) {
        ReactGA.event({
            category: eventName,
            action: 'Click Event',
            label: eventName
        });
    } 

    return {
        recordGAEvent
    }
}